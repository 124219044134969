import React, { useState } from "react";
import { useReportsContext } from "../../contexts/ReportsContextProvider";
import { ThreeDots } from "react-loader-spinner";
import ReportsList from "./ReportsList";

const LedgerReports = () => {
  const [formData, setFormData] = useState({
    from: "",
    to: "",
  });
  const [loading, setLoading] = useState(false);

  const { ReportsFromTo, financialReports } = useReportsContext();

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    console.log("Values :", value);
    setFormData({
      ...formData,
      [name]: value,
    });
  };

  financialReports && console.log("financialReports :", financialReports);

  const handleSubmit = async () => {
    setLoading(true);
    const { from, to } = formData;
    await ReportsFromTo({ from: from, to: to });
    setLoading(false);
  };

  return (
    <div>
      {/* {{-- start date and end date --}} */}
      <div className="w-full p-4">
        <section className="w-full p-4 bg-white rounded-lg">
          <div class="flex flex-col md:flex-row md:items-center md:space-x-8 space-y-4 md:space-y-0">
            <div class="relative border rounded-md border-black px-2 py-4 w-full md:w-1/2">
              <input
                type="date"
                name="from"
                value={formData.from}
                onChange={handleInputChange}
                class="w-full appearance-none p-0 border-0 text-base font-normal  focus:outline-none focus:ring-0 focus:border-0 focus:bg-white"
              />
              <p class="absolute -top-3 left-5 bg-white rounded-lg px-2">
                From
              </p>
            </div>
            <div class="relative border rounded-md border-black px-2 py-4 w-full md:w-1/2">
              <input
                type="date"
                name="to"
                value={formData.to}
                onChange={handleInputChange}
                class="w-full p-0 border-0 text-base font-normal  focus:outline-none focus:ring-0 focus:border-0 focus:bg-white"
              />
              <p class="absolute -top-3 left-5 bg-white px-2">To</p>
            </div>
          </div>
          <div className="flex justify-end">
            <button
              onClick={handleSubmit}
              class=" px-16 py-2 rounded-md ms-auto mt-5 text-white bg-[#8DC67B] text-md font-semibold"
            >
              {loading ? (
                <div className="flex items-center justify-center w-full">
                  <ThreeDots
                    color="#ffffff"
                    height={20}
                    width={40}
                    className="py-2"
                  />
                </div>
              ) : (
                "Save"
              )}
            </button>
          </div>
          {/* Reports List */}
          <ReportsList />
        </section>
      </div>
    </div>
  );
};

export default LedgerReports;
