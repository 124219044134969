import React, { useEffect } from 'react'
import { useEventContext } from '../../contexts/EventContextProvider'
import {format} from 'date-fns';
import { IoMdArrowRoundBack } from "react-icons/io";
import { useNavigate } from 'react-router-dom';


const EventBookedBy = () => {
    const {getEventsBookedUsers, bookedEventRecord, loadingERecord} = useEventContext();
    const navigate=useNavigate();


    useEffect(()=>{
        const tok=localStorage.getItem('token');
        const parseTok=JSON.parse(tok);
        const windowLocation=window.location.href;
        const id = windowLocation.split('/').pop();
        getEventsBookedUsers(parseTok , id)
    },[])

    useEffect(()=>{
        if(!loadingERecord){
            console.log("bookedEventRecord ***** =>", bookedEventRecord)
        }
    },[loadingERecord])
   

  return (
    <div>
        {
            !loadingERecord ? (
                <div className="overflow-x-auto ">
                  {
                    bookedEventRecord?.length === 0 ? (<>  
                    <h1 className='text-center font-bold text-2xl'>No such user booked this event</h1>
                    <p className='text-center'> 
                    <button className='mx-auto rounded-lg p-2 mt-2 bg-[#8DC67B] flex align-middle gap-1' onClick={()=>navigate(-1)}><IoMdArrowRoundBack className='mt-1'/> Go back</button>
                      </p>
                    </>
                  ):
                <table className="w-full text-sm text-left text-gray-500 dark:text-gray-400">
                  <thead className="text-xs text-gray-700 uppercase bg-gray-50 dark:bg-gray-700 dark:text-gray-400">
                  <tr>
                  <th scope="col" className="px-4 py-3">
                        Name
                      </th>
                      <th scope="col" className="px-4 py-3">
                        Email
                      </th>
                      <th scope="col" className="px-4 py-3">
                        Type
                      </th>
                      <th scope="col" className="px-4 py-3">
                        Created
                      </th>
                      <th scope="col" className="px-4 py-3">
                        No of Persons
                      </th>
                      <th scope="col" className="px-4 py-3">
                        Price
                      </th>
                      {/* <th scope="col" className="px-4 py-3">
                        Details
                      </th> */}
                      <th scope="col" className="px-4 py-3">
                        Booked by
                      </th>
                      {/* <th scope="col" className="px-4 py-3">
                        Actions
                      </th> */}
                    </tr>
                  </thead>
                  <tbody>
                    {/* Table rows */}
                    {/* Map your data here and create rows as needed */}
                    {
                        bookedEventRecord && bookedEventRecord?.map((record, index)=>(
                        <tr className="border-b dark:border-gray-700">
                            <td className="px-4 py-3">{record?.user?.name}</td>
                            <td className="px-4 py-3">{record?.user?.email}</td>
                            <td className="px-4 py-3">{record?.plan?.type}</td>
                            <td className="px-4 py-3">{format(record?.created_at, 'yyyy/MM/dd')}</td>
                            <td className="px-4 py-3 text-center">{record?.plan?.no_of_persons}</td>
                            <td className="px-4 py-3">{record?.plan?.price}</td>
                            <td className="px-4 py-3">{record?.booking_type}</td>

                        </tr>

                        )) 
                        
                    }
                  </tbody>
                </table>
                  }
                  {/* Pagination */}
              <div className="flex justify-center bg-[#8DC67B]">
              {/* <ReactPaginate
                    previousLabel={"Prev"}
                    nextLabel={"Next"}
                    breakLabel={"..."}
                    pageCount={pageCount}
                    marginPagesDisplayed={2}
                    onPageChange={handlePageChange}
                    containerClassName={"pagination"}
                    activeClassName={"active"}
                    disabledClassName={"disabled"}
          /> */}
              </div>
              </div>
            ):(
                <div className="p-2 m-2 bg-white md:m-10 md:p-10 rounded-3xl">
                <h5 className="font-bold text-center text-md">Loading ....</h5>
              </div>)
        }
    </div>
  )
}

export default EventBookedBy