import React, { createContext, useContext, useEffect, useState } from "react";
import ApiTemplate from "../apis/api_template";
import { jsonHeader, multiFormHeader } from "../apis/header";
import { useAuthContext } from "./AuthContextProvider";

const FinanceContext = createContext();

export const FinanceContextProvider = ({ children }) => {
    const [financeList, setFinanceList] = useState(null);
    const [pageCount, setPageCount] = useState(0);
    const [currentPage, setCurrentPage] = useState(1);
    const [limit, setLimit] = useState(20);  
    const {token} = useAuthContext();
    const [singleFinanceData,setSingleFinanceData]=useState(null);
    const [isSeen,setIsSeen]=useState(false);
    const [updateStatus,setUpdateStatus]= useState(null);

    const getList = async (page = currentPage, itemsPerPage = limit) => {
        try {
            // const token = ""; // Add token retrieval logic if needed
            const headers = {
                // Do not include Content-Type header here, as FormData automatically sets it
                ...multiFormHeader(token),
              };
            const response = await ApiTemplate("get", `/api/v1/finance/list?page=${page}&limit=${itemsPerPage}`, {}, headers);

            if (response && response["success"] === true) {
                setFinanceList(response?.data?.data);
                setPageCount(response?.data?.last_page);
                setLimit(response?.data?.per_page);
            } else {
                console.log(response["message"], response.message);
            }
        } catch (error) {
            console.error("Error during API call:", error);
        }
    };

    const getSingleFinance = async(singleId)=>{
        const headers={
            ...jsonHeader(token)
        }
        try {
            const response = await ApiTemplate("get", `/api/v1/finance/single/${singleId}`, {}, headers);
            if(response && response["success"] === true){
                console.log("Single Finance:", response.data);
                setSingleFinanceData(response?.data);
                setIsSeen(true);
            }
        } catch (error) {
                console.log("Error :", error);
        }
    }

    const updateViewFinance = async (id,status) =>{
        setUpdateStatus(false);
        const headers={
            ...multiFormHeader(token)
        }
        try {
            const response = await ApiTemplate("post", `/api/v1/finance/update/${id}`, status , headers);
            if(response && response["success"] === true){
                console.log("Single Finance:", response.data);
                setUpdateStatus(true);

                getList();
            }
        } catch (error) {
                console.log("Error :", error);
                setUpdateStatus(true);

        }
    }

    useEffect(() => {
        getList(currentPage, limit);
    }, [currentPage, limit,isSeen]);

    return (
        <FinanceContext.Provider
            value={{
                financeList,
                getList,
                currentPage,
                setCurrentPage,
                pageCount,
                limit,
                setLimit,
                getSingleFinance,
                singleFinanceData,
                updateViewFinance,
                updateStatus
            }}
        >
            {children}
        </FinanceContext.Provider>
    );
};

export const useFinanceContext = () => {
    return useContext(FinanceContext);
};
