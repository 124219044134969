// EventContext.js

import React, { createContext, useContext, useEffect, useState } from "react";
import ApiTemplate from "../apis/api_template";
import { jsonHeader } from "../apis/header";

const EventContext = createContext();

export const EventContextProvider = ({ children }) => {
  const [allEvents, setAllEvents] = useState(null);
  const [addEventId, setAddEventId] = useState(null);
  const [singleEventId, setSingleEventId] = useState(null);
  const [bookedEvents, setBookedEvents]= useState([]);
  const [singleBookedEvent, setSingleBookedEvent]=useState(null);
  const [pageCount, setPageCount] = useState(0);
  const [bookedEventRecord,setBookedEventRecord]=useState(null);
  const [loadingERecord,setLoadingERecord]=useState(false);
  const [currentPage, setCurrentPage] = useState(1);
  const [limit, setLimit] = useState(15);

  const [eventLimit,setEventLimit]=useState(10);
  const [eventCurrentPage,setEventCurrentPage]=useState(1);
  const [eventPageCount, setEventPageCount] = useState(0);


  const getEvents = async (token, page = eventCurrentPage, itemPerPage = eventLimit) => {
    try {
      const headers = jsonHeader(token);

      const response = await ApiTemplate("get", `/api/v1/events?page=${page}&limit=${itemPerPage}`, {}, headers);
      if (response && response["success"] === true) {
        console.log(response["data"]);
        setAllEvents(response["data"]["events"].data);
        setEventCurrentPage(response["data"]["events"].current_page);
        setEventPageCount(response["data"]["events"].last_page);
        setEventLimit(response["data"]["events"].per_page);
      } else {
        console.log(response["message"], response.message);
      }
    } catch (error) {
      console.error("Error during API call:", error);
    }
  };

  const getEventsBookedUsers =async (token , id) =>{
    const tok = JSON.parse(localStorage.getItem('token'))
    setLoadingERecord(true)
    try {
      const headers = jsonHeader(tok);
      const response = await ApiTemplate("get", `/api/v1/getBookingAgainstEvent/${id}`, {}, headers);
      if (response && response?.success === true) {
        console.log("getEventsBookedUsers :" ,response?.data);
        setBookedEventRecord(response?.data);
        setLoadingERecord(false)
      } else {
        console.log(response["message"], response.message);
      }
    } catch (error) {
      console.error("Error during API call:", error);
      setLoadingERecord(false)
    }
  }

  const getEventbyId = async (token,id) => {
    try {
      
      const headers = jsonHeader(token);

      const response = await ApiTemplate("get", `/api/v1/event/${id}`, {}, headers);
      if (response && response["success"] === true) {
        console.log(response["data"]);
        setSingleEventId(response["data"]);
      } else {
        console.log(response["message"], response.message);
      }
    } catch (error) {
      console.error("Error during API call:", error);
    }
  };

  const addEvent = async (token, eventData) => {
    try {
      const headers = {
        "Content-Type": "multipart/form-data;",
        "X-CSRF-TOKEN": document.head
          .querySelector('meta[name="csrf-token"]')
          ?.getAttribute("content"),
        Authorization: `Bearer ${token}`,
      };

      const response = await ApiTemplate("post", "/api/v1/event", eventData, headers);
      if (response && response["success"] === true) {
        console.log(response["data"]);
        // setAllCategories([...allCategories, response["data"]]);
        setAddEventId(response["data"]["id"])
        getEvents(token) 
        return true;
      } else {
        console.log(response["message"], response.message);
        return false;

      }
    } catch (error) {
      console.error("Error during API call:", error);
      return false;

    }
  };

  const addExtraImages = async (token, eventData) => {
    const tok = JSON.parse(localStorage.getItem('token'))
    try {
      const headers = {
        "Content-Type": "multipart/form-data;",
        "X-CSRF-TOKEN": document.head
          .querySelector('meta[name="csrf-token"]')
          ?.getAttribute("content"),
        Authorization: `Bearer ${tok}`,
      };

      const response = await ApiTemplate("post", "/api/v1/event/extra/images", eventData, headers);
      if (response && response["success"] === true) {
        console.log(response["data"]);
        // setAllCategories([...allCategories, response["data"]]);
        return true;
      } else {
        console.log(response["message"], response.message);
        return false;

      }
    } catch (error) {
      console.error("Error during API call:", error);
      return false;

    }
  };

  const addEventAccomodation = async (token, eventData) => {
    try {
      const headers = {
        "Content-Type": "application/json",
        "X-CSRF-TOKEN": document.head
          .querySelector('meta[name="csrf-token"]')
          ?.getAttribute("content"),
        Authorization: `Bearer ${token}`,
      };

      const response = await ApiTemplate("post", "/api/v1/event/accomodation", eventData, headers);
      if (response && response["success"] === true) {
        console.log(response["data"]);
        // setAllCategories([...allCategories, response["data"]]);
        return true;
      } else {
        console.log(response["message"], response.message);
        return false;

      }
    } catch (error) {
      console.error("Error during API call:", error);
      return false;

    }
  };

  const addEventFeatures = async (token, eventData) => {
    try {
      const headers = {
        "Content-Type": "application/json",
        "X-CSRF-TOKEN": document.head
          .querySelector('meta[name="csrf-token"]')
          ?.getAttribute("content"),
        Authorization: `Bearer ${token}`,
      };

      const response = await ApiTemplate("post", "/api/v1/event/features", eventData, headers);
      if (response && response["success"] === true) {
        console.log(response["data"]);
        // setAllCategories([...allCategories, response["data"]]);
        return true;
      } else {
        console.log(response["message"], response.message);
        return false;

      }
    } catch (error) {
      console.error("Error during API call:", error);
      return false;

    }
  };

  const addEventPlans = async (token, eventData) => {
    try {
      const headers = {
        "Content-Type": "application/json",
        "X-CSRF-TOKEN": document.head
          .querySelector('meta[name="csrf-token"]')
          ?.getAttribute("content"),
        Authorization: `Bearer ${token}`,
      };

      const response = await ApiTemplate("post", "/api/v1/event/plan", eventData, headers);
      if (response && response["success"] === true) {
        // console.log(response["data"]);
        // setAllCategories([...allCategories, response["data"]]);
        return true;
      } else {
        console.log(response["message"], response.message);
        return false;

      }
    } catch (error) {
      console.error("Error during API call:", error);
      return false;

    }
  };

  const deleteEvent = async (token, eventId) => {
    try {
      // Implement the logic to delete an event using your API here
    } catch (error) {
      console.error("Error during API call:", error);
    }
  };

  const updateEvent = async (token, eventId, eventData) => {
    try {
      // Implement the logic to update an event using your API here
    } catch (error) {
      console.error("Error during API call:", error);
    }
  };

  const allBookedEvents = async (token, page = currentPage, itemsPerPage = limit) => {
    console.log("allBookedEvents token :", token)
    try {
      const headers = jsonHeader(token);

      const response = await ApiTemplate("get", `/api/v1/bookings?page=${page}&limit=${itemsPerPage}`, {}, headers);
      if (response && response["success"] === true) {
        console.log("allBookedEvents :" , response["data"]);
        setBookedEvents(response["data"].data);
        setPageCount(response?.data?.last_page)
        setLimit(response?.data?.per_page)
      } else {
        console.log(response["message"], response.message);
      }
    } catch (error) {
      console.error("Error during API call:", error);
    }
  };

  const specificBookedEventRecord = async (token,id) => {
    console.log("specificBookedEventRecord  :", token, " " , id)
    try {
      setSingleBookedEvent(null)
      const headers = jsonHeader(token);
      const response = await ApiTemplate("get", `/api/v1/booking/${id}`, {}, headers);
      if (response && response["success"] === true) {
        console.log("allBookedEvents :" , response["data"]);
        setSingleBookedEvent(response["data"]);
      } else {
        console.log(response["message"], response.message);
      }
    } catch (error) {
      console.error("Error during API call:", error);
    }
  };

  useEffect(() => {
    const token=localStorage.getItem('token');
    const parseToken = JSON.parse(token);
    if(window.location.href.includes('booked-events')){
      allBookedEvents(parseToken,currentPage, limit);
    }
}, [currentPage, limit]);

useEffect(()=>{
  getEvents()
},[eventCurrentPage])

  return (
    <EventContext.Provider
      value={{
        allEvents,
        getEvents,
        addEvent,
        addExtraImages,
        deleteEvent,
        updateEvent,
        getEventbyId,
        singleEventId,
        addEventId, setAddEventId, addEventAccomodation, addEventFeatures ,addEventPlans,
        allBookedEvents,
        bookedEvents,
        specificBookedEventRecord,
        singleBookedEvent,
        getEventsBookedUsers,
        setCurrentPage,
        currentPage,
        setPageCount, 
        pageCount,
        bookedEventRecord,
        loadingERecord,
        setEventCurrentPage,
        eventPageCount,
        eventCurrentPage
      }}
    >
      {children}
    </EventContext.Provider>
  );
};

export const useEventContext = () => {
  const eventContextValue = useContext(EventContext);
  return eventContextValue;
};
