import React, { useEffect, useState } from 'react';
import { useEventContext } from '../../contexts/EventContextProvider';
import { Link } from 'react-router-dom';
import {format} from 'date-fns'
import ReactPaginate from 'react-paginate';
import { ColorRing } from 'react-loader-spinner';
import ClickAwayListener from 'react-click-away-listener';

const BookedEvents = () => {
    const [isModalOpen,setOpenModal]=useState(false);
    const {allBookedEvents, bookedEvents, specificBookedEventRecord, singleBookedEvent, setCurrentPage, pageCount} = useEventContext();

    useEffect( ()=>{
        const token=localStorage.getItem('token');
        const stringifyToken=JSON.parse(token);
        console.log("stringifyToken :", stringifyToken)
         allBookedEvents(stringifyToken)
    },[])

    const singleBooking =async (event) =>{
        const token=localStorage.getItem('token');
        const stringifyToken=JSON.parse(token);
        setOpenModal(true);
        await specificBookedEventRecord(stringifyToken, event.id);
    }

    const closeModal = () =>{
        setOpenModal(false)
    }

    const handlePageChange = (page = { selected: 0 }) => {
        const selectedPage = page.selected + 1;  
        console.log("page.selected + 1 :", page.selected + 1)
        setCurrentPage(selectedPage);
    };
    
  return (
   <div className='px-5 py-10'>
      {bookedEvents ? (
        <div className="relative overflow-hidden bg-white shadow-md dark:bg-gray-800 sm:rounded-lg">
          <h1 className="font-semibold text-3xl px-4 pt-4">Booked events list</h1>

          <div className="overflow-x-auto ">
            <table className="w-full text-sm text-left text-gray-500 dark:text-gray-400">
              <thead className="text-xs text-gray-700 uppercase bg-gray-50 dark:bg-gray-700 dark:text-gray-400">
              <tr>
              <th scope="col" className="px-4 py-3">
                    Name
                  </th>
                  <th scope="col" className="px-4 py-3">
                    Email
                  </th>
                  <th scope="col" className="px-4 py-3">
                    Type
                  </th>
                  <th scope="col" className="px-4 py-3">
                    Created
                  </th>
                  <th scope="col" className="px-4 py-3">
                    No of Persons
                  </th>
                  <th scope="col" className="px-4 py-3">
                    Price
                  </th>
                  {/* <th scope="col" className="px-4 py-3">
                    Details
                  </th> */}
                  <th scope="col" className="px-4 py-3">
                    View Details
                  </th>
                  {/* <th scope="col" className="px-4 py-3">
                    Actions
                  </th> */}
                </tr>
              </thead>
              <tbody>
                {/* Table rows */}
                {/* Map your data here and create rows as needed */}
                {
                  bookedEvents ? bookedEvents?.map((event, index) => ( <tr key={index} className="border-b dark:border-gray-700">
                    <td className="px-4 py-3">{event.user.name}</td>
                    <td className="px-4 py-3">{event.user.email}</td>
                    <td className="px-4 py-3">{event.plan.type}</td>
                    <td className="px-4 py-3">{format(event.created_at, 'yyyy/MM/dd')}</td>
                    <td className="px-4 py-3 text-center">{event.plan.no_of_persons}</td>
                    <td className="px-4 py-3">{event.plan.price}</td>

                    {/* <td className="px-4 py-3">{event.booking_details}</td> */}
                    {/* <td className="px-4 py-3">{event.date_at.split("-")[0]}</td> */}
                    <td className="px-2 py-3">
                      <ul className="flex items-center justify-center gap-3 py-1 text-sm text-gray-700 dark:text-gray-200">
                        <li onClick={()=>singleBooking(event)}>
                          {/* <Link to={"/event/view/" + event.id} > */}
                            <img src={'images/sidebar/Show.svg'} className='cursor-pointer' alt="Show" />
                          {/* </Link> */}
                        </li>

                      </ul>
                    </td>
                  </tr>
                  )):<h1>"Loading..."</h1>
                }

                {/* Map more rows here */}
              </tbody>
            </table>
              {/* Pagination */}
          <div className="flex justify-center bg-[#8DC67B]">
          <ReactPaginate
                previousLabel={"Prev"}
                nextLabel={"Next"}
                breakLabel={"..."}
                pageCount={pageCount}
                marginPagesDisplayed={2}
                onPageChange={handlePageChange}
                containerClassName={"pagination"}
                activeClassName={"active"}
                disabledClassName={"disabled"}
      />
          </div>
          </div>
       
        </div>
      ) : (
        <div className="p-2 m-2 bg-white md:m-10 md:p-10 rounded-3xl">
          <h5 className="font-bold text-center text-md">Loading ...</h5>
        </div>
      )}

{isModalOpen && (
  <div className="fixed  inset-0 flex items-center justify-center z-50 bg-black bg-opacity-50 shadow-lg">
    {singleBookedEvent ? 
    <ClickAwayListener onClickAway={closeModal}>
    <div className="finance-user-modal bg-white dark:bg-gray-800 rounded-lg shadow-lg p-6  relative md:w-[40%] w-[90%] sm:w-[90%] max-h-[80%] overflow-auto">
      <button
        onClick={closeModal}
        className="absolute text-4xl font-semibold top-2 right-2 text-gray-500 hover:text-black dark:text-gray-400"
      >
        &times;
      </button>
        {/* userImage */}
        {/* <div className='userImage mb-2'>
        <img className="rounded-full w-40 h-40" src={selectedUserId.profile_picture ? `${baseUrl}${selectedUserId.profile_picture}` :""} alt="not found" />
      </div> */}
      {/* Username */}
      <div className='username mb-2'>
        <h2 className="text-lg font-semibold">Name</h2>
        <p className="shadow-sm p-2 rounded-xl font-medium text-slate-700">{singleBookedEvent?.user?.name}</p>
      </div>

      {/* Email */}
      <div className='useremail mb-2'>
        <h2 className="text-lg font-semibold">Email</h2>
        <p className="shadow-sm p-2 rounded-xl font-medium text-slate-700">{singleBookedEvent?.user?.email}</p>
      </div>

       {/* Phone */}
       <div className='useremail mb-2'>
        <h2 className="text-lg font-semibold">Phone</h2>
        <p className="shadow-sm p-2 rounded-xl font-medium text-slate-700">{singleBookedEvent?.user?.phone}</p>
      </div>

       {/* Gender */}
       <div className='userGender mb-2'>
        <h2 className="text-lg font-semibold">Type</h2>
        <p className="shadow-sm p-2 rounded-xl font-medium text-slate-700">{singleBookedEvent?.plan?.type}</p>
      </div>

        {/* Country */}
        <div className='userCountry mb-2'>
        <h2 className="text-lg font-semibold">Created at</h2>
        <p className="shadow-sm p-2 rounded-xl font-medium text-slate-700">{format(singleBookedEvent?.created_at, 'yyyy/MM/dd')}</p>
      </div>

      {/* DOB */}
      <div className='userdob mb-2'>
        <h2 className="text-lg font-semibold">Number of persons</h2>
        <p className="shadow-sm p-2 rounded-xl font-medium text-slate-700">{singleBookedEvent?.plan?.no_of_persons}</p>
      </div>

      {/* Age */}
      <div className='userAge mb-2'>
        <h2 className="text-lg font-semibold">Price</h2>
        <p className="shadow-sm p-2 rounded-xl font-medium text-slate-700">{singleBookedEvent?.plan?.price}</p>
      </div>

       {/* Phone */}
       <div className='userPhone mb-2'>
        <h2 className="text-lg font-semibold">Booking details</h2>
        <p className="shadow-sm p-2 rounded-xl font-medium text-slate-700">{singleBookedEvent?.booking_details}</p>
      </div>

      
    </div>
    </ClickAwayListener>
    :<h1 className='className="finance-user-modal bg-white dark:bg-gray-800 rounded-lg shadow-lg p-6  relative md:w-[40%] w-[90%] sm:w-[90%] max-h-[80%] overflow-auto'>
    <div className="flex items-center justify-center w-full my-20 text-center"><ColorRing
            visible={true}
            height="80"
            width="80"
            ariaLabel="blocks-loading"
            wrapperStyle={{}}
            wrapperClass="blocks-wrapper"
            colors={["black"]}
          /></div></h1>}
  </div>
)}
    </div>
  )
}

export default BookedEvents