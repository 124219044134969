import React, { useState } from 'react';
import { Link } from 'react-router-dom';


const Sidebar = () => {
    const [activeMenuItems, setActiveMenuItems] = useState([]);
    const [activeSubItems, setActiveSubItems] = useState([]);
    const menuItems = [
        {
            label: 'Dashboard',
            icon: 'icon_1.svg',
            route: '/',
            subItems: [],
        },
        {
            label: 'Events',
            icon: 'icon_2.svg',
            // route: 'all-events',
            subItems: [
                {
                    label: 'Create Event',
                    icon: 'sub_icon_2.svg',
                    route: 'create-event',
                },
                {
                    label: 'All Events',
                    icon: 'sub_icon_3.svg',
                    route: 'all-events',

                },
                {
                    label: 'Booked Events',
                    icon: 'sub_icon_3.svg',
                    route: 'booked-events',

                },
            ],
        },
        {
            label: 'Location',
            icon: 'icon_3.svg',
            route: 'location',
            subItems: [],
        },
        {
            label: 'Categories',
            icon: 'icon_7.svg',
            route: 'categories',
            subItems: [],
        },
        {
            label: 'Coordinators',
            icon: 'icon_8.svg',
            route: 'coordinators',
            subItems: [],
        },
        {
            label: 'Blogs',
            icon: 'icon_4.svg',
            route: 'blogs',
            subItems: [],
        },
        {
            label: 'Users',
            icon: 'icon_5.svg',
            route: 'users',
            subItems: [],
        },
        {
            label: 'Setting',
            icon: 'icon_6.svg',
            // route: 'setting',
            subItems: [
                {
                    label: 'Icons',
                    icon: 'sub_icon_2.svg',
                    route: 'icons',
                },
                {
                    label: 'App Media',
                    icon: 'sub_icon_2.svg',
                    route: 'app-media',
                },
                {
                    label: 'Contact Support',
                    icon: 'sub_icon_2.svg',
                    route: 'queries',
                },
            ],
        },
        {
            label: 'Finance',
            icon: 'mdi_finance.svg',
            route: 'finance',
            subItems: [],
        },
        {
            label: 'Ledger Reports',
            icon: 'ledger.svg',
            route: 'ledger-reports',
            subItems: [],
        },
    ];

    const isMenuActive = (menuItem) => {
        return activeMenuItems.includes(menuItem.route);
    };

    const isSubItemActive = (subItem) => {
        return activeSubItems.includes(subItem.route);
    };

    const toggleSubMenu = (route) => {
        if (activeMenuItems.includes(route)) {
            // Deactivate the main menu item
            setActiveMenuItems([]);
        } else {
            // Activate the main menu item and deactivate all others
            setActiveSubItems([]);
            setActiveMenuItems([route]);
        }
    };

    const toggleSubItem = (route) => {
        if (activeSubItems.includes(route)) {
            // Deactivate the submenu item
            setActiveSubItems(activeSubItems.filter((item) => item !== route));
            setActiveMenuItems([]);
        } else {
            // Activate the submenu item and deactivate all others
            setActiveMenuItems([]);
            setActiveSubItems([route]);
        }
    };



    return (
        <div className="min-h-screen col-span-1 py-8 bg-white border-r px-7 border-r-gray-100">
            <div className="flex items-center pl-6 space-x-3 text-lg font-bold">
                <img src={'/images/sidebar/logo.svg'} alt="Logo" />
            </div>
            <div className="mt-12">
                {/* <Link to={'/finance'}>
            <button type="button" className="text-white bg-gray-800 hover:bg-gray-900 focus:outline-none focus:ring-4 focus:ring-gray-300 font-medium rounded-lg text-sm px-5 py-2.5 me-2 mb-2 dark:bg-gray-800 dark:hover:bg-gray-700 dark:focus:ring-gray-700 dark:border-gray-700">Dark</button>
                </Link> */}
                <ul className="flex flex-col space-y-2 text-sm text-gray-500 sidebar-menu">
                    {menuItems.map((menuItem, key) => (
                        <div key={key} >
                            <SidebarItem menuItem={menuItem} isSubItemActive={isSubItemActive} isMenuActive={isMenuActive} />
                        </div>
                    ))}
                </ul>
            </div>
        </div>
    );
};

export default Sidebar;


function SidebarItem({ menuItem, isSubItemActive, isMenuActive }) {
    const [isShowSubItems, setIsShowSubItems] = useState(false)
    return <>
        <div>
            <Link
                to={menuItem.route}
                onClick={() => menuItem.subItems.length > 0 && setIsShowSubItems(!isShowSubItems)}
                className={`flex items-center justify-between px-6 py-3 space-x-3 rounded-lg cursor-pointer sidebar-menu ${isMenuActive(menuItem) ? 'bg-green-200' : ''} hover:bg-green-200`}
            >
                <div className="flex items-center space-x-3">
                    <img
                        src={`/images/sidebar/${menuItem.icon}`}
                        alt={`${menuItem.label} Icon`}
                    />
                    <span>{menuItem.label}</span>
                </div>
                {menuItem.subItems.length > 0 && (
                    <img
                        className={`arrow-icon ${isShowSubItems?"rotate-90":"rotate-180"}`}
                        src={`/images/sidebar/arrow_1.svg`}
                        alt={`${menuItem.label} Icon`}
                    />
                )}
            </Link>
        </div>
        {isShowSubItems && menuItem.subItems.length > 0 && (
            <ul className="flex-col mt-2 space-y-2 ms-7 flex" >
                {menuItem.subItems.map((subItem, subKey) => (
                    <Link key={subKey} to={subItem.route}>
                        <li
                            onClick={() => setIsShowSubItems(true)}
                            className={`flex items-center justify-between px-6 py-3 space-x-3 rounded-lg ${isSubItemActive(subItem) ? 'bg-green-200' : 'hover:bg-green-200'}`}
                        >
                            <div className="flex items-center space-x-3">
                                <img
                                    src={`/images/sidebar/${subItem.icon}`}
                                    alt={`${subItem.label} Icon`}
                                />
                                <span>{subItem.label}</span>
                            </div>
                        </li>
                    </Link>
                ))}
            </ul>
        )}
    </>
}
