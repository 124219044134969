import { createContext, useContext, useState } from "react";
import ApiTemplate from "../apis/api_template";
import { jsonHeader } from "../apis/header";

const DashboardContext = createContext();

export const DashboardContextProvider = ({children}) =>{
  const [dashboardData, setDashboardData] = useState(null);

  const allBookedEvents = async () => {
    const token = JSON.parse(localStorage.getItem('token'));
    try {
      const headers = jsonHeader(token);

      const response = await ApiTemplate("get", '/api/v1/dashboard', {}, headers);
      if (response && response["success"] === true) {
        console.log("allBookedEvents :" , response["data"]);
        setDashboardData(response["data"]);
      } else {
        console.log(response["message"], response.message);
      }
    } catch (error) {
      console.error("Error during API call:", error);
    }
  };

  return(
    <DashboardContext.Provider value={{
      allBookedEvents,
      dashboardData
    }}>
      {children}
    </DashboardContext.Provider>
  )
}

export const useDashboardContext = () =>{
  const dashboardContextValue = useContext(DashboardContext);
  return dashboardContextValue;
}