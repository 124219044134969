import React, { useEffect, useRef, useState } from 'react';
import { useFinanceContext } from '../../contexts/FinanceContextProvider';
import ReactPaginate from 'react-paginate';
import { FaExternalLinkSquareAlt } from "react-icons/fa";
import { Link } from 'react-router-dom';
import { baseUrl } from '../../apis/base_url';
import { ThreeDots } from 'react-loader-spinner';
import ClickAwayListener from 'react-click-away-listener';

const FinanceList = () => {
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [selectedUserId, setSelectedUserId] = useState(null);
  const [checkStatus,setCheckStatus] = useState('');
  // const modalRef = useRef();
  const { financeList, setCurrentPage, currentPage, pageCount, getSingleFinance, updateViewFinance, getList, updateStatus } = useFinanceContext();

  const openModal =async (fuser) => {
    await getSingleFinance(fuser.unique_string);
    await getList();
    setSelectedUserId(fuser);
    setIsModalOpen(true);
    // handlePageChange({ selected: 0 });
  };

  const closeModal = () => {
    setIsModalOpen(false);
    setSelectedUserId(null);
  };


  const handlePageChange = (page = { selected: 0 }) => {
    const selectedPage = page.selected + 1;  
    setCurrentPage(selectedPage);
};

const toggleModal = () =>{
  setIsModalOpen(false)
}

useEffect(()=>{
  console.log("Selected User :", selectedUserId)
},[selectedUserId])


  return (
    <div className="px-5 py-10">
      {financeList ? (
        
        <>
        {
          financeList.length === 0 ? <h1 className='shadow-md text-center font-bold text-2xl p-2 rounded-lg'>No list contains</h1>:
        <div className="relative overflow-hidden bg-white shadow-md dark:bg-gray-800 sm:rounded-lg">
          <h1 className="font-semibold text-3xl px-4 py-4">Financing</h1>

          <div className="overflow-x-auto">
            <table className="w-full text-sm text-left text-gray-500 dark:text-gray-400">
              <thead className="text-xs text-gray-700 uppercase bg-gray-50 dark:bg-gray-700 dark:text-gray-400">
                <tr>
                  <th scope="col" className="px-4 py-3">
                    Name
                  </th>
                  <th scope="col" className="px-4 py-3">
                    Email
                  </th>
                  <th scope="col" className="px-4 py-3">
                    Profession
                  </th>
                  <th scope="col" className="px-4 py-3">
                    Country
                  </th>
                  <th
                    scope="col"
                    className="px-4 py-3 flex items-center justify-center"
                  >
                    View
                  </th>
                </tr>
              </thead>
              <tbody>
                {financeList.map((fuser, index) => (
                  <tr
                    key={index}
                    className={`border-b dark:border-gray-700 ${
                      fuser?.is_seen === 1 ? "bg-green-200" : ""
                    }`}
                  >
                    <td className="px-4 py-3">{fuser?.full_name}</td>
                    <td className="px-4 py-3">{fuser.email}</td>
                    <td className="px-4 py-3">{fuser?.profession}</td>
                    <td className="px-4 py-3">{fuser?.country}</td>
                    <td className="px-2 py-3">
                      <ul className="flex items-center justify-center py-1 text-sm text-gray-700 dark:text-gray-200">
                        <li className="flex gap-3 align-middle">
                          <Link
                            to={`/finance/single/${fuser.unique_string}`}
                            target="_blank"
                          >
                            <FaExternalLinkSquareAlt
                              size={20}
                              className="cursor-pointer"
                            />
                          </Link>
                          <button onClick={() => openModal(fuser)}>
                            <img src={"images/sidebar/Show.svg"} alt="Show" />
                          </button>
                        </li>
                      </ul>
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>

          {/* Pagination */}
          <div className="flex justify-center bg-[#8DC67B]">
            <ReactPaginate
              previousLabel={"Prev"}
              nextLabel={"Next"}
              breakLabel={"..."}
              pageCount={pageCount}
              marginPagesDisplayed={2}
              onPageChange={handlePageChange}
              containerClassName={"pagination"}
              activeClassName={"active"}
              disabledClassName={"disabled"}
              forcePage={currentPage - 1}
            />
          </div>
        </div>
        }
        </>
        
      ) : (
        <div className="p-2 m-2 bg-white md:m-10 md:p-10 rounded-3xl">
          <h5 className="font-bold text-center text-md">Loading ....</h5>
        </div>
      )}

      {/* Modal */}
      {isModalOpen && (
        <div className="fixed  inset-0 flex items-center justify-center z-50 bg-black bg-opacity-50 shadow-lg">
          {selectedUserId && (
          <ClickAwayListener onClickAway={toggleModal}>
            <div
              // ref={modalRef}
              className="finance-user-modal bg-white dark:bg-gray-800 rounded-lg shadow-lg p-6  relative md:w-[40%] w-[90%] sm:w-[90%] max-h-[80%] overflow-auto"
            >
              <button
                onClick={closeModal}
                className="absolute text-4xl font-semibold top-2 right-2 text-gray-500 hover:text-black dark:text-gray-400"
              >
                &times;
              </button>
              {/* userImage */}
              <div className="userImage mb-2">
                <img
                  className="w-52 rounded-lg"
                  src={
                    selectedUserId.profile_picture
                      ? `${baseUrl}${selectedUserId.profile_picture}`
                      : ""
                  }
                  alt="not found"
                />
              </div>
              {/* Username */}
              <div className="username mb-2">
                <h2 className="text-lg font-semibold">Full Name</h2>
                <p className="shadow-sm p-2 rounded-xl font-medium text-slate-700">
                  {selectedUserId?.full_name}
                </p>
              </div>

              {/* Email */}
              <div className="useremail mb-2">
                <h2 className="text-lg font-semibold">Email</h2>
                <p className="shadow-sm p-2 rounded-xl font-medium text-slate-700">
                  {selectedUserId?.email}
                </p>
              </div>

              {/* Gender */}
              <div className="userGender mb-2">
                <h2 className="text-lg font-semibold">Gender</h2>
                <p className="shadow-sm p-2 rounded-xl font-medium text-slate-700">
                  {selectedUserId?.gender}
                </p>
              </div>

              {/* Country */}
              <div className="userCountry mb-2">
                <h2 className="text-lg font-semibold">Country</h2>
                <p className="shadow-sm p-2 rounded-xl font-medium text-slate-700">
                  {selectedUserId?.country}
                </p>
              </div>

              {/* DOB */}
              <div className="userdob mb-2">
                <h2 className="text-lg font-semibold">DOB</h2>
                <p className="shadow-sm p-2 rounded-xl font-medium text-slate-700">
                  {selectedUserId?.dob}
                </p>
              </div>

              {/* Age */}
              <div className="userAge mb-2">
                <h2 className="text-lg font-semibold">Age</h2>
                <p className="shadow-sm p-2 rounded-xl font-medium text-slate-700">
                  {selectedUserId?.age}
                </p>
              </div>

              {/* Phone */}
              <div className="userPhone mb-2">
                <h2 className="text-lg font-semibold">Phone number</h2>
                <p className="shadow-sm p-2 rounded-xl font-medium text-slate-700">
                  {selectedUserId?.phone}
                </p>
              </div>

              {/* Employment Status */}
              <div className="useremployment mb-2">
                <h2 className="text-lg font-semibold">Employment Status</h2>
                <p className="shadow-sm p-2 rounded-xl font-medium text-slate-700">
                  {selectedUserId?.emp_status}
                </p>
              </div>

              {/* profession */}
              <div className="userprofession mb-2">
                <h2 className="text-lg font-semibold">profession</h2>
                <p className="shadow-sm p-2 rounded-xl font-medium text-slate-700">
                  {selectedUserId.profession}
                </p>
              </div>

              {/* Annual gross income */}
              <div className="userannualgrossincome mb-2">
                <h2 className="text-lg font-semibold">Annual gross income</h2>
                <p className="shadow-sm p-2 rounded-xl font-medium text-slate-700">
                  {selectedUserId?.a_gross_income}
                </p>
              </div>

              {/* Academic Degrees */}
              <div className="useracademic mb-2">
                <h2 className="text-lg font-semibold">Academic Degrees</h2>
                <p className="shadow-sm p-2 rounded-xl font-medium text-slate-700">
                  {JSON.parse(selectedUserId?.degree).map((val, index)=>(
                    <p>{index + 1}{") "}{val}</p>
                  ))}
                </p>
                {/* <p className="shadow-sm p-2 rounded-xl font-medium text-slate-700">2: Master’s</p>
        <p className="shadow-sm p-2 rounded-xl font-medium text-slate-700">3: PhD</p> */}
              </div>

              {/* Social Media */}
              {selectedUserId.social_media_link !== null && <div className="usersocialmedia mb-2">
                <h2 className="text-lg font-semibold">Social Media</h2>
                <p className="shadow-sm p-2 rounded-xl font-medium text-slate-700">
                  <a href={selectedUserId?.social_media_link} target="_blank">
                    {selectedUserId?.social_media_link}
                  </a>
                </p>
              </div>}

              {/* Package url */}
              <div className="userpackageurl mb-2">
                <h2 className="text-lg font-semibold">Package</h2>
                <p className="shadow-sm p-2 rounded-xl font-medium text-slate-700">
                  <a href={selectedUserId?.pkg_link} target="_blank">
                    {selectedUserId?.pkg_link}
                  </a>
                </p>
              </div>

              {selectedUserId?.status === "" || selectedUserId?.status === "Pending" ? (
                <div className="accept-reject flex gap-5">
                  {/* Reject button */}
                  <button
                    type="button"
                    className="px-5 py-2 bg-red-400 text-white"
                    // disabled={!updateStatus}
                    onClick={async () => {
                      setCheckStatus("Rejected");
                      await updateViewFinance(selectedUserId?.unique_string, {
                        status: "Rejected",
                      });
                      setIsModalOpen(false);
                      setSelectedUserId(null);
                      setCheckStatus(null)
                    }}
                  >
                    {!updateStatus && checkStatus === "Rejected" ? <div className="flex items-center justify-center w-full">
                        <ThreeDots
                          color="#ffffff"
                          height={20}
                          width={40}
                          className="py-2"
                        />
                      </div> : "Rejected"}
                  </button>
                  {/* Accept button */}
                  <button
                    type="button"
                    className="px-5 py-2 bg-[#8DC67B]"
                    // disabled={!updateStatus}
                    onClick={async () => {
                      setCheckStatus("Accepted");
                      await updateViewFinance(selectedUserId?.unique_string, {
                        status: "Accepted",
                      });
                      setIsModalOpen(false);
                      setSelectedUserId(null);
                      setCheckStatus(null)
                    }}
                  >
                     {!updateStatus && checkStatus === "Accepted" ? <div className="flex items-center justify-center w-full">
                        <ThreeDots
                          color="#ffffff"
                          height={20}
                          width={40}
                          className="py-2"
                        />
                      </div> : "Accepted"}
                    
                  </button>
                </div>
              ) : selectedUserId?.status === "Accepted" ? (
                <h1 className='p-3 text-center font-bold text-[#8DC67B] text-lg rounded-lg'>Request accepted</h1>
              ) : selectedUserId?.status === "Rejected" ? (
                <h1 className='p-3 text-center font-bold text-[red] text-lg rounded-lg'>Request rejected</h1>
              ) : (
                ""
              )}
            </div>
          </ClickAwayListener>
          )}
        </div>
      )}
    </div>
  );
};

export default FinanceList;



                  