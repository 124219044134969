import React, { useEffect, useState } from 'react';
import { useAuthContext } from '../../contexts/AuthContextProvider';
import { useEventContext } from '../../contexts/EventContextProvider';
import { Link } from 'react-router-dom';
import { ColorRing } from 'react-loader-spinner';
import ReactPaginate from 'react-paginate';

function AllEvent() {

  const [showActionsDropdown, setShowActionsDropdown] = useState(false);
  const [showFilterDropdown, setShowFilterDropdown] = useState(false);
  const [searchInput, setSearchInput] = useState('');
  const { token } = useAuthContext();
  const { allEvents,
    getEvents, getEventsBookedUsers, setEventCurrentPage, eventPageCount,eventCurrentPage} = useEventContext();

  const getAllEvents = async () => {
    await getEvents(token);
  }

  useEffect(() => {
    getAllEvents();
  }, []);

  // Function to toggle the Actions dropdown
  const toggleActionsDropdown = () => {
    setShowActionsDropdown(!showActionsDropdown);
  };

  // Function to toggle the Filter dropdown
  const toggleFilterDropdown = () => {
    setShowFilterDropdown(!showFilterDropdown);
  };

  const handlePageChange = (page) => {
    const selectedPage = page.selected + 1;
    setEventCurrentPage(selectedPage)
  }

  return (
    <div className='px-5 py-10'>
      {allEvents ? (
        <div className="relative overflow-hidden bg-white shadow-md dark:bg-gray-800 sm:rounded-lg">
          <h1 className="font-semibold text-3xl px-4 pt-4">EVENTS</h1>

          <div className="flex flex-col items-center justify-between p-4 space-y-3 md:flex-row md:space-y-0 md:space-x-4">
            <div className="w-full md:w-1/2">
              {/* search */}
              {/* <form className="flex items-center">
                <label htmlFor="simple-search" className="sr-only">
                  Search
                </label>
                <div className="relative w-full">
                  <div className="absolute inset-y-0 left-0 flex items-center pl-3 pointer-events-none">
                    <svg
                      aria-hidden="true"
                      className="w-5 h-5 text-gray-500 dark:text-gray-400"
                      fill="currentColor"
                      viewBox="0 0 20 20"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        fillRule="evenodd"
                        d="M8 4a4 4 0 100 8 4 4 0 000-8zM2 8a6 6 0 1110.89 3.476l4.817 4.817a1 1 0 01-1.414 1.414l-4.816-4.816A6 6 0 012 8z"
                        clipRule="evenodd"
                      />
                    </svg>
                  </div>
                  <input
                    type="text"
                    id="simple-search"
                    className="block w-full py-2 text-sm text-gray-900 border border-gray-300 rounded-lg bg-gray-50 focus:ring-primary-500 focus:border-primary-500 ps-10 pe-3 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-primary-500 dark:focus:border-primary-500"
                    placeholder="Search"
                    required
                    value={searchInput}
                    onChange={(e) => setSearchInput(e.target.value)}
                  />
                </div>
              </form> */}
            </div>


          </div>

          <div className="overflow-x-auto ">
            <table className="w-full text-sm text-left text-gray-500 dark:text-gray-400">
              <thead className="text-xs text-gray-700 uppercase bg-gray-50 dark:bg-gray-700 dark:text-gray-400">
                <tr>
                  <th scope="col" className="px-4 py-3">
                    Title
                  </th>
                  <th scope="col" className="px-4 py-3">
                    Category
                  </th>
                  <th scope="col" className="px-4 py-3">
                    No of Persons
                  </th>
                  <th scope="col" className="px-4 py-3">
                    Price
                  </th>
                  <th scope="col" className="px-4 py-3">
                    Start Time
                  </th>
                  <th scope="col" className="px-4 py-3">
                    End Time
                  </th>
                  <th scope="col" className="px-4 py-3">
                    Event users
                  </th>
                  <th scope="col" className="px-4 py-3">
                    Actions
                  </th>
                </tr>
              </thead>
              <tbody>
                {/* Table rows */}
                {/* Map your data here and create rows as needed */}
                {
                  allEvents && allEvents?.map((event, index) => {
                    return <tr key={index} className="border-b dark:border-gray-700">
                      <td className="px-4 py-3 font-medium text-gray-900 whitespace-nowrap dark:text-white">
                        {event.title}
                      </td>
                      <td className="px-4 py-3">{event.category.name}</td>
                      <td className="px-4 py-3">{event.total_group_members}</td>
                      <td className="px-4 py-3">{event.price}</td>
                      <td className="px-4 py-3">{event.date_at.split("-")[0]}</td>
                      <td className="px-4 py-3">{event.date_at.split("-")[1]}</td>
                      <td className="px-4 py-3">
                        <Link to={`event-booked-by/${event.id}`}>
                        <button className='bg-[#8DC67B] py-1 px-1 rounded-lg text-white font-semibold'
                      // onClick={()=>getBookingAgainsEvent(event.id)}
                      >Users</button>
                        </Link>
                      </td>
                      <td className="px-2 py-3">
                        <ul className="flex items-center justify-center gap-3 py-1 text-sm text-gray-700 dark:text-gray-200">
                          <li>
                            <Link to={"/event/view/" + event.id} >
                              <img src={'images/sidebar/Show.svg'} alt="Show" />
                            </Link>
                          </li>

                        </ul>
                      </td>
                    </tr>
                  })
                }

                {/* Map more rows here */}
              </tbody>
            </table>
           
          </div>
           {/* Pagination */}
           <div className="flex justify-center bg-[#8DC67B] mt-5 rounded-lg">
              <ReactPaginate
                previousLabel={"Prev"}
                nextLabel={"Next"}
                breakLabel={"..."}
                pageCount={eventPageCount}
                marginPagesDisplayed={2}
                onPageChange={handlePageChange}
                containerClassName={"pagination"}
                activeClassName={"active"}
                disabledClassName={"disabled"}
                forcePage={eventCurrentPage - 1}
              />
            </div>
          {/* <nav className="flex flex-col items-start justify-between p-4 space-y-3 md:flex-row md:items-center md:space-y-0" aria-label="Table navigation">
          <span className="text-sm font-normal text-gray-500 dark:text-gray-400">
            Showing
            <span className="mx-1 font-semibold text-gray-900 dark:text-white">1-10</span>
            of
            <span className="mx-1 font-semibold text-gray-900 dark:text-white">1000</span>
          </span>
          <ul className="inline-flex items-stretch -space-x-px">
            <li>
              <a href="#" className="flex items-center justify-center h-full py-1.5 px-3 ml-0 text-gray-500 bg-white rounded-l-lg border border-gray-300 hover-bg-gray-100 hover-text-gray-700 dark-bg-gray-800 dark-border-gray-700 dark-text-gray-400 dark-hover-text-white dark-hover-bg-gray-700">
                <span className="sr-only">Previous</span>
                <svg className="w-5 h-5" aria-hidden="true" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg">
                  <path fillRule="evenodd" d="M12.707 5.293a1 1 0 010 1.414L9.414 10l3.293 3.293a1 1 0 01-1.414 1.414l-4-4a1 1 0 010-1.414l4-4a1 1 0 011.414 0z" clipRule="evenodd" />
                </svg>
              </a>
            </li>
            <li>
              <a href="#" className="flex items-center justify-center px-3 py-2 text-sm leading-tight text-gray-500 bg-white border border-gray-300 hover-bg-gray-100 hover-text-gray-700 dark-border-gray-700 dark-bg-gray-700 dark-text-white">1</a>
            </li>
            <li>
              <a href="#" className="flex items-center justify-center px-3 py-2 text-sm leading-tight text-gray-500 bg-white border border-gray-300 hover-bg-gray-100 hover-text-gray-700 dark-border-gray-700 dark-bg-gray-700 dark-text-white">2</a>
            </li>
            <li>
              <a href="#" aria-current="page" className="z-10 flex items-center justify-center px-3 py-2 text-sm leading-tight border text-primary-600 bg-primary-50 border-primary-300 hover-bg-primary-100 hover-text-primary-700 dark-border-gray-700 dark-bg-gray-700 dark-text-white">3</a>
            </li>
            <li>
              <a href="#" className="flex items-center justify-center px-3 py-2 text-sm leading-tight text-gray-500 bg-white border border-gray-300 hover-bg-gray-100 hover-text-gray-700 dark-border-gray-700 dark-bg-gray-700 dark-text-white">...</a>
            </li>
            <li>
              <a href="#" className="flex items-center justify-center px-3 py-2 text-sm leading-tight text-gray-500 bg-white border border-gray-300 hover-bg-gray-100 hover-text-gray-700 dark-border-gray-700 dark-bg-gray-700 dark-text-white">100</a>
            </li>
            <li>
              <a href="#" className="flex items-center justify-center h-full py-1.5 px-3 leading-tight text-gray-500 bg-white rounded-r-lg border border-gray-300 hover-bg-gray-100 hover-text-gray-700 dark-bg-gray-800 dark-border-gray-700 dark-text-gray-400 dark-hover-text-white dark-hover-bg-gray-700">
                <span className="sr-only">Next</span>
                <svg className="w-5 h-5 rotate-180" aria-hidden="true" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg">
                  <path fillRule="evenodd" d="M12.707 5.293a1 1 0 010 1.414L9.414 10l3.293 3.293a1 1 0 01-1.414 1.414l-4-4a1 1 0 010-1.414l4-4a1 1 0 011.414 0z" clipRule="evenodd" />
                </svg>
              </a>
            </li>
          </ul>
        </nav> */}
        </div>
      ) : (
        <div className="p-2 m-2 bg-white md:m-10 md:p-10 rounded-3xl">
          <div className="flex items-center justify-center w-full my-20 text-center"><ColorRing
            visible={true}
            height="80"
            width="80"
            ariaLabel="blocks-loading"
            wrapperStyle={{}}
            wrapperClass="blocks-wrapper"
            colors={["black"]}
          /></div>
        </div>
      )}
    </div>
  );
}

export default AllEvent;
