import React, { useState } from 'react'
import AddEvent from './add_event'
import AddExtraImages from './add_extra_imges'
import AddAccomodation from './add_accomodation'
import AddFeatures from './add_features'
import AddPlans from './add_plans'
import Questions from './questions'

const TabHeader = ({ tabsList, activeTab, onTabClick }) => {
  return (
    <div className='flex space-x-1 mt-5 bg-[#8DC67B1A] bg-opacity-10 py-2 px-2 rounded-md'>
      {tabsList.map((tab, index) => (
        <div
          className={`flex items-center justify-start cursor-default ${
            index === activeTab ? 'active-tab' : ''
          }`}
          key={index}
          
        >
          {tab.active ? (
            <img src='/images/sidebar/circle_filled.svg' alt='circle_filled' />
          ) : (
            <img src='/images/sidebar/circle.svg' alt='circle' />
          )}
          <img src='/images/sidebar/line.svg' alt='line' />
          <h1 className='font-normal text-md text-[#49454F] px-2'>
            {tab.label}
          </h1>
          <img src='/images/sidebar/arrow_farward.svg' alt='arrow next' />
        </div>
      ))}
    </div>
  )
}

const TabBody = ({ activeTab, onNext }) => {
  // Add logic to render different tab bodies based on the activeTab index
  const renderTabBody = () => {
    switch (activeTab) {
      case 0:
        return <AddEvent onNext={onNext} />
      case 1:
        return <AddExtraImages onNext={onNext} />
      case 2:
        return <AddAccomodation onNext={onNext} />
      case 3:
        return <AddFeatures onNext={onNext} />
      case 4:
        return <Questions onNext={onNext} />
      case 5:
        return <AddPlans onNext={onNext} />
      default:
        return null
    }
  }

  return <div>{renderTabBody()}</div>
}

const CreateEventTabs = () => {
  const [activeTab, setActiveTab] = useState(0)
  const [tabsList, setTabsList] = useState([
    {
      label: 'Add Event',
      active: true
    },
    {
      label: 'Extra Images',
      active: false
    },
    {
      label: 'Accommodations',
      active: false
    },
    {
      label: 'Features',
      active: false
    },
    {
      label: 'Parameter',
      active: false
    },
    {
      label: 'Plans',
      active: false
    }
  ])

  const handleTabClick = index => {
    setActiveTab(index)
  }

  const handleNext = () => {
    // Add logic for what happens when "Next" is clicked within each tab body
    console.log('Next clicked in tab body', activeTab)

    if (activeTab + 1 < tabsList.length) {
      const newTabsList = tabsList.map((tab, index) => ({
        ...tab,
        active: index <= activeTab + 1
      }))

      setTabsList(newTabsList)
      setActiveTab(activeTab + 1)
    }
  }

  return (
    <div>
      {/* Other content */}
      <TabHeader
        tabsList={tabsList}
        activeTab={activeTab}
        onTabClick={handleTabClick}
      />
      <TabBody activeTab={activeTab} onNext={handleNext} />
      {/* Rest of your component */}
    </div>
  )
}

export default CreateEventTabs
