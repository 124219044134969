import React, { useState } from 'react'
import { Link } from 'react-router-dom'
import { useEventContext } from '../../../contexts/EventContextProvider';
import { useAuthContext } from '../../../contexts/AuthContextProvider';
import { ThreeDots } from 'react-loader-spinner';

function AddExtraImages({ onNext }) {
  const [uploadedImages, setUploadedImages] = useState([]);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState('');

  const { addExtraImages, addEventId } = useEventContext()
  const { token } = useAuthContext();


  const handleFileChange = (e) => {
    const files = Array.from(e.target.files);
    setUploadedImages([...uploadedImages, ...files]);
  };

  const handleNextButtonClick = async () => {
    setLoading(true);

    if (uploadedImages.length === 0) {
      setError('Please upload at least one image.');
      setLoading(false);
      return;
    }
    if(!addEventId){
      setError('Please add event first.');
      setLoading(false);
      return;
    }

    let data = new FormData();
    data.append('event_id', addEventId);
    uploadedImages.map((file,index) => {

      data.append(`extra_image[${index}]`, file);
    })


    const response = await addExtraImages(token, data);
    // Reset input field after processing
    if (!response) {
      setError("Oops! Event not added.please try again.")
    }
    else {
      onNext();
    }
    setLoading(false);
    // Perform any necessary logic before moving to the next step
    // For example, you might want to perform validation here

    // Simulate a delay for demonstration purposes (replace with your actual logic)
    // Simulated delay of 1 second (remove this in your actual implementation)
  };



  return (
    <section class='w-full p-4 rounded-md  '>
      <section class='w-full px-10 pt-5 pb-10 rounded-md  bg-white'>
        {/* {{-- title --}} */}
        <div class='flex space-x-3'>
          <img src="images/sidebar/arrow_back.svg" alt='arrow_back' class='w-5' />
          <h1 class='font-semibold text-md'>Add Extra Images</h1>
        </div>

        {/* {{-- form  --}} */}
        <div class='flex flex-col space-y-6 mt-5'>
          {/* {{-- upload banner image and upload card image --}} */}
          <div class='flex items-center w-full space-x-8'>
            <div class='relative border rounded-md border-black px-2 py-4 w-full'>
              <input
                type='file'
                name='extra_images[]'
                id='extra_images'
                multiple
                onChange={handleFileChange}
                class='w-full p-0 border-0 text-base font-normal focus:outline-none focus:ring-0 focus:border-0 focus:bg-white'
              />
              <p class='absolute -top-3 left-5 bg-white px-2'>
                Upload Extra Images
              </p>
            </div>
          </div>

          {error &&
            <p className="text-sm font-normal text-center text-red-500">
              {error}
            </p>}

          {/* <!-- Container to display uploaded images --> */}
          <div id='uploadedImagesContainer' class='mt-4 flex flex-wrap'>
            {/* {{-- cancel and save button --}} */}
            <div class='flex items-center justify-end  w-full space-x-8'>
              <Link to={"/all-events"} >
                <button class='border px-16 py-2 rounded-md border-black text-[#8DC67B] text-md font-semibold'>
                  Cancel
                </button>
              </Link>
              <button
                onClick={handleNextButtonClick}
                class=' px-16 py-2 rounded-md  text-white bg-[#8DC67B] text-md font-semibold'
              >
                {loading
                  ? <div className="flex items-center justify-center w-full">
                    <ThreeDots
                      color="#ffffff"
                      height={20}
                      width={40}
                      className="py-2"
                    />
                  </div>
                  : 'Next'}
              </button>
            </div>
          </div>
        </div>
      </section>
    </section>
  )
}

export default AddExtraImages
