import React, { useEffect, useState } from 'react';
import { Formik, Form, ErrorMessage } from "formik";
import * as Yup from "yup";
import { IoIosArrowDown, IoIosArrowUp } from "react-icons/io";
import { useQuestionsContext } from '../../../contexts/QuestionsContextProvider';
import { useAuthContext } from '../../../contexts/AuthContextProvider';
import { ThreeDots } from 'react-loader-spinner';
import { useEventContext } from '../../../contexts/EventContextProvider';
import { Link } from 'react-router-dom';

const Questions = ({ onNext }) => {
  const [openDropdown, setOpenDropdown] = useState(null);
  const { token } = useAuthContext();
  const { allQuestions, getQuestions, questionFilterSave, postQuestion, postSuccess } = useQuestionsContext();
  const { addEventId } = useEventContext();
  const [error, setError] = useState('');
  const [loading, setLoading] = useState(false);
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    getQuestions(token).then(() => setIsLoading(false));
  }, []);

  const toggleDropdownFaq = (id) => {
    setOpenDropdown(openDropdown === id ? null : id);
  };

  if (isLoading || !allQuestions) {
    return <div className="p-2 m-2 bg-white md:m-10 md:p-10 rounded-3xl">
      <h5 className="font-bold text-center text-md">Loading ....</h5>
    </div>;
  }

  const validationSchema = Yup.object(
    allQuestions.reduce((acc, question) => {
      acc[`selectedAnswers_${question.id}`] = Yup.string()
        .required(`Please select an answer for "${question.question}"`);
      return acc;
    }, {})
  );

  const handleNext = async () => {
    setLoading(true);
    if (!addEventId) {
      setError('Please add event first.');
      setLoading(false);
      return false;  // Return false if there is an error
    }
    // setLoading(false);

    const response = getQuestions(token).then(() => setLoading(false));

    // Reset input field after processing
    if (!response) {
      setError("Oops! Event not added.please try again.")
    }
    else {
      // onNext();
    }
    // setLoading(true);
    return true;


  };

  return (
    <div className="flex items-center justify-center bg-black bg-opacity-50 z-50">
      <div className="accommodation bg-white h-[80%] w-full p-6 mx-auto shadow-lg">
        <Formik
          enableReinitialize
          initialValues={allQuestions.reduce((acc, question) => {
            acc[`selectedAnswers_${question.id}`] = "";
            return acc;
          }, {})}
          validationSchema={validationSchema}
          onSubmit={async (values, { setSubmitting }) => {
            console.log("Values :", values.answer)
            setLoading(true)

            const results = allQuestions.reduce((acc, question, index) => {
              const questionKey = `q${index + 1}`;  // Map to "q1", "q2", etc.
              acc[questionKey] = values[`selectedAnswers_${question.id}`];
              return acc;
            }, {});
            const payload = {
              event_id: addEventId,
              ...results
            };
            // if(addEventId){
            await questionFilterSave(token, JSON.stringify(payload));
            if(!postQuestion){
              onNext();
            }
            setSubmitting(false);
          }}
        >
          {({ values, setFieldValue, isSubmitting }) => (
            <Form>
              <div className="faq container mx-auto p-4 space-y-6">
                {allQuestions.map((vals) => (
                  <div key={vals.id} className="relative mb-6">
                    <div className="question font-semibold text-lg text-black">
                      <p>{vals.question}</p>
                    </div>

                    <div
                      className="custom-dropdown relative border border-slate-500 rounded-lg p-3 mt-2 cursor-pointer w-full flex justify-between items-center"
                      onClick={() => toggleDropdownFaq(vals.id)}
                    >
                      <p className="m-0 text-black">
                        {values[`selectedAnswers_${vals.id}`] || "Select an answer"}
                      </p>
                      <div className="absolute top-3 right-3">
                        {openDropdown === vals.id ? (
                          <IoIosArrowDown className="font-bold text-black" size={22} />
                        ) : (
                          <IoIosArrowUp className="font-bold text-black" size={22} />
                        )}
                      </div>
                    </div>

                    {openDropdown === vals.id && (
                      <div className="absolute z-10 bg-white rounded-lg w-full mt-1 shadow-md">
                        {vals.answers.map((answer) => (
                          <div
                            key={answer}
                            onClick={() => {
                              setFieldValue(`selectedAnswers_${vals.id}`, answer);
                              toggleDropdownFaq(vals.id);
                            }}
                            className={`p-3 cursor-pointer rounded-md mb-1 ${values[`selectedAnswers_${vals.id}`] === answer
                              ? "bg-[#8DC67B] text-white"
                              : "bg-gray-100 text-gray-800 hover:bg-[#8DC67B]"
                              }`}
                          >
                            {answer}
                          </div>
                        ))}
                      </div>
                    )}

                    <ErrorMessage
                      name={`selectedAnswers_${vals.id}`}
                      component="div"
                      className="text-red-500 mt-2"
                    />
                  </div>
                ))}

                {error &&
                  <p className="text-sm font-normal text-center text-red-500">
                    {error}
                  </p>}

                <div className='flex items-center justify-center  w-full space-x-8'>
                  <Link to={"/all-events"} >
                    <button className='border px-16 py-2 rounded-md border-black text-[#8DC67B] text-md font-semibold'>
                      Cancel
                    </button>
                  </Link>
                  <button type='submit' disabled={isSubmitting} className=' px-16 py-2 rounded-md  text-white bg-[#8DC67B] text-md font-semibold'>
                    {postQuestion
                      ? <div className="flex items-center justify-center w-full">
                        <ThreeDots
                          color="#ffffff"
                          height={20}
                          width={40}
                          className="py-2"
                        />
                      </div>
                      : 'Save'}
                  </button>
                </div>
              </div>
            </Form>
          )}
        </Formik>
      </div>
    </div>
  );
};

export default Questions;
